import { useMemo } from "react";
import CountUp from "react-countup";
import { Text, TextProps } from "../Text";

interface BalanceProps extends TextProps {
  value: number;
  decimals?: number;
  unit?: string;
  isDisabled?: boolean;
  prefix?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  strikeThrough?: boolean;
  startFromValue?: boolean;
  noAnimation?: boolean;
  pack?: boolean;
}

const Balance: React.FC<React.PropsWithChildren<BalanceProps>> = ({
  value,
  color = "text",
  decimals = 3,
  isDisabled = false,
  unit,
  prefix,
  onClick,
  strikeThrough,
  startFromValue = false,
  pack = false,
  ...props
}) => {
  const [packedValue, packedUnit] = useMemo(() => {
    if (pack) {
      const units = ['', 'K', 'M', 'B', 'T', 'Q']
      const k = 1000
      const i = value < 1 ? 0 : Math.floor(Math.log(value) / Math.log(k))
      return [value / k ** i, units[i]]
    }
    return [value, undefined]
  }, [pack, value])
  const prefixProp = useMemo(() => (prefix ? { prefix } : {}), [prefix]);
  const suffixProp = useMemo(() => (unit || packedUnit ? { suffix: `${packedUnit ?? ''}${unit ?? ''}` } : {}), [unit, packedUnit]);
  
  return (
    <CountUp
      start={startFromValue ? packedValue : 0}
      preserveValue
      delay={0}
      end={packedValue}
      {...prefixProp}
      {...suffixProp}
      decimals={decimals}
      duration={1}
      separator=","
    >
      {({ countUpRef }) => (
        <Text
          color={isDisabled ? "textDisabled" : color}
          style={{ textDecoration: strikeThrough ? "line-through" : "none" }}
          onClick={onClick}
          {...props}
        >
          <span ref={countUpRef} />
        </Text>
      )}
    </CountUp>
  );
};

export default Balance;
